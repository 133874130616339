<template>
  <div>

    <div class="vx-row mt-2 mb-4">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <b>Prova Prática:</b>
      </div>
      <div class="vx-col sm:w-1/1 w-full mb-2" v-if="praticalTestName">
        {{ praticalTestName }}
      </div>
    </div>

    <Grid
      ref="grid"
      :key="key"
      hide_actions="true"
      hide_searchbar="true"
      :service="praticalTestService"
      route_name="practical_tests"
      :fetchOnMounted="false"
      @grid-UPDATED="gridLoaded"
      :route_grid_path="`${id}/teachers/${teacherId}/students/grid`">

      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('practical_test.student.name') }}</vs-th>
        <!-- <vs-th key="email" sort-key="email">{{ $t('practical_test.student.email') }}</vs-th>-->
        <vs-th key="identifier" sort-key="identifier">{{ $t('practical_test.student.identifier') }}</vs-th>
        <vs-th>
          <div class="grid grid-cols-3 justify-items-center flex w-full">
            <div class="flex justify-center">
              <vs-chip transparent color="success" class="text-base">Não iniciada</vs-chip>
            </div>
            <div class="flex justify-center">
              <vs-chip transparent color="warning" class="text-base">Iniciada</vs-chip>
            </div>
            <div class="flex justify-center">
              <vs-chip transparent color="primary" class="text-base">Finalizada</vs-chip>
            </div>
          </div>
        </vs-th>
      </template>

      <template slot="filter">
        <div class="flex">
          <vs-input
            id="student_identifier"
            :label="$t('practical_test.student.identifier')"
            v-model="identifier">
          </vs-input>

            <vs-button
              class="ml-4"
              style="margin-top: 20px !important;"
              type="border"
              :disabled="identifier === ''"
              @click="filter">Pesquisar</vs-button>
            <vs-button
              class="ml-3"
              style="margin-top: 20px !important;"
              type="border"
              @click="goBack">
              {{ $t('common.back') }}
            </vs-button>

        </div>
      </template>

      <template v-slot:gridData="props">

        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id" class="break-all p-1 pl-4">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="25%" key="name" class="break-all p-1 pl-4">
            <div> {{ tr.data.name }}</div>
          </vs-td>
          <vs-td width="15%" key="identifier" class="break-all p-1 pl-4">
            <div> {{ tr.data.identifier }}</div>
          </vs-td>
          <vs-td width="60%">

            <vs-table :data="sections">
              <template slot-scope="{data}">
                <vs-tr
                  :key="data[i]"
                  v-for="(trSection, i) in data"
                  :class="classSection(trSection, tr.data)"
                  style="border-bottom: thin solid;">
                  <vs-td width="70%">
                    <div v-html-safe="trSection.data.title" class=""></div>
                  </vs-td>
                  <vs-td width="30%">
                    <div class="grid grid-cols-2">
                      <div class="flex">
                        <feather-icon
                          icon="PlayIcon"
                          svgClasses="h-7 w-7 mb-1 mr-3 stroke-current cursor-pointer"
                          v-tooltip.auto="{content: 'Iniciar avaliação', delay: {show: 500,hide: 500}}"
                          @click="() => openPraticalTest(tr, trSection.id)"/>

                      </div>
                      <div class="justify-center items-center space-y-1 p-1" :class="getStatisticsClass(getStatisticsAnalyticTotalQuestion(trSection, tr.data), getStatisticsAnalyticTotalQuestionAnswered(trSection, tr.data))">
                        <div class="flex" v-tooltip.auto="{ content: 'Total de Itens', delay: {show: 500, hide: 500}}">
                          <feather-icon icon="ListIcon" svgClasses="h-5 w-5"/>
                          <span class="ml-1">{{getStatisticsAnalyticTotalQuestion(trSection, tr.data)}}</span>
                        </div>
                        <div class="flex" v-tooltip.auto="{ content: 'Total de Itens Avaliados', delay: {show: 500, hide: 500}}">
                          <feather-icon icon="CheckIcon" svgClasses="h-5 w-5"/>
                          <span class="ml-1">{{getStatisticsAnalyticTotalQuestionAnswered(trSection, tr.data)}}</span>
                        </div>
                      </div>
                    </div>
                  </vs-td>

                </vs-tr>
              </template>
            </vs-table>

          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import PracticalTestStudentService from '@/services/api/PracticalTestStudentService'
import PracticalTestService from '@/services/api/PracticalTestService'
import PracticalTestTeacherService from '@/services/api/PracticalTestTeacherService'

export default {
  props: {
    id: { // PraticalTestId
      default: null,
      type: Number
    },
    teacherId: { // PraticalTestTeacherId
      default: null,
      type: Number
    },
    identifier: {
      default: '',
      type: String
    },
  },
  data: () => ({
    service: null,
    praticalTestService: null,
    practicalTestTeacherService: null,
    sections : [],
    praticalTestName: null
  }),
  methods: {
    goBack() {
      this.$router.push('/practical_tests/tests')
    },
    openPraticalTest(entity, sectionId) {
      const practicalTestStudentId = entity.id//To preserve url filter

      this.$router.history.push({ name: 'practical_test_teacher_student', query: {identifier:this.identifier}})
      //?identifier=${this.identifier}
      this.$router.push(`/form_practical/${practicalTestStudentId}/${sectionId}`)
    },
    gridLoaded(response) {
      this.sections = this._.get(response, 'sections')
      if(response.model.total === 0){
        this.notifyWarning(this.$vs, this.$t('nenhum-registro-encontrado'), 4000)
      }
    },
    filter() {
      this.$refs['grid'].fillCustomFilters([{key: 'identifier', value: this.identifier}])
      this.$refs['grid'].fetchGridData()
      this.updateRouteInFilter()
    },
    classSection(section, data) {
      const s = this.getSectionAnalytic(data, section)
      //console.log('s...', s)
      // return s !== null && s.started_at !== null
      if (s !== null) {
        //if (s.started_at !== null && s.answered_questions.length === s.qty_question) {
        if (s.finished_at && s.finished_at !== null) {
          return 'section-finished'
        } else if (s.started_at !== null) {
          return 'section-started'
        } else {
          return 'section-not-started'
        }
      }
      return 'section-not-started'
    },
    getStatisticsAnalyticTotalQuestion(section) {
      return (section && section.qtyQuestion) ? section.qtyQuestion : 0
    },
    getStatisticsAnalyticTotalQuestionAnswered(section, data) {
      const s = this.getSectionAnalytic(data, section)

      return (s && s.answered_questions) ? s.answered_questions.length : 0
    },
    getStatisticsClass(total, qtyAnswered){
      return total != qtyAnswered ? 'vs-chip-danger con-color bg-chip-transparent rounded shadow': ''
    },
    getSectionAnalytic(data, section) {
      const analytic = JSON.parse(data.analytic)
      //console.log('analytic...', analytic)
      if (!analytic) return null
      const s = analytic.sections.filter(item => {
        return item.id === section.id
      }).shift()
      return s
    },
    updateRouteInFilter(){
      //this.$router.push({ name: 'practical_test_teacher_student', query: {identifier:this.identifier}});
    }
  },
  mounted() {
    let queryParamIdentifier = this._.get(this.$router.history.current.query, 'identifier')

    if (this.identifier !== '') {
      this.filter()
    }else if(queryParamIdentifier && queryParamIdentifier !== ''){
      this.identifier = queryParamIdentifier;
      this.filter()
    }

    this.practicalTestTeacherService.read(this.teacherId).then(
      response => {
      this.praticalTestName = this._.get(response, 'practical_test.questionnaire_name');
    })
  },
  beforeMount() {
    this.service = PracticalTestStudentService.build(this.$vs)
    this.praticalTestService = PracticalTestService.build(this.$vs)
    this.practicalTestTeacherService = PracticalTestTeacherService.build(this.$vs)
  }
}
</script>

<style lang="scss">
  .section-not-started {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1);
  }

  .section-started {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1);
  }

  .section-finished {
    background: rgba(var(--vs-primary),.15);
    color: rgba(var(--vs-primary),1);
  }

  .play-color {
    color: white;
  }

  .section-text-color {
    color: white;
  }
</style>
